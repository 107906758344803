import { Component, Vue, Prop } from "vue-property-decorator";
import { INodeRender, NodePosition } from "@/app/models/node-types";
const DefaultPlainDraggable = require("plain-draggable");
const PlainDraggable = DefaultPlainDraggable.default;

@Component({ name: "flow-node" })
export default class FlowNode extends Vue {
  @Prop({ required: true })
  public node!: INodeRender;
  public draggable = false;
  public connections: number[] = [];

  mounted(): void {
    const draggableElement = <HTMLElement>this.$el;
    const minHeight = 50;
    let clientHeight =
      draggableElement.clientHeight < minHeight
        ? minHeight
        : draggableElement.clientHeight;
    const position = this.node.position;
    const draggable = new PlainDraggable(draggableElement);
    draggable.onMove = this.onMove;
    PlainDraggable.draggableCursor = "move";
    this.draggable = draggable;

    const socketHeightAndMargin = 30;
    const sockets = this.node.sockets;
    const totalHeightSockets = socketHeightAndMargin * sockets;
    if (totalHeightSockets + socketHeightAndMargin >= clientHeight) {
      clientHeight = totalHeightSockets + socketHeightAndMargin;
    }
    const centerSocketTop = totalHeightSockets / 2;

    for (let n = 0; n < this.node.sockets; n++) {
      const socketElements = <Element[]>this.$refs[`${this.node.key}_${n}`];
      if (socketElements && socketElements.length) {
        const socketElement = socketElements[0];
        socketElement.setAttribute(
          "style",
          `top: ${
            clientHeight / 2 - (centerSocketTop - 5) + n * socketHeightAndMargin
          }px`
        );
      }
    }

    draggableElement.setAttribute(
      "style",
      `
        top: ${position ? position.top + "px" : "100px"};
        left: ${position ? position.left + "px" : "100px"};
        min-height: ${clientHeight}px;
      `
    );

    draggable.position();
  }

  setConnection(connection: number): void {
    this.connections = this.connections.concat(connection);
  }

  onMove(newPosition: NodePosition): void {
    this.$emit("move", this.node.id, newPosition);
  }
}
