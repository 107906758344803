import { Node } from '@/app/models/node';

export class BotNode extends Node {
  public name: string;
  public position: any;

  constructor(name = 'Nodo inicial') {
    super();
    this.name = name;
    this.position = {
      left: 100,
      top: 100,
    };
  }
}
